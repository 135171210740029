import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  ViewEncapsulation,
} from "@angular/core";
import { MatRippleModule } from "@angular/material/core";

type ChipStatus =
  | "primary"
  | "info"
  | "success"
  | "warning"
  | "danger"
  | "basic";

type ChipSize = "sm" | "md";
type ChipRounded = "sm" | "md" | "full";

@Component({
  standalone: true,
  selector: "chip-ui",
  styleUrl: "chip.ui.scss",
  templateUrl: "chip.ui.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatRippleModule],
})
export class ChipUi {
  readonly status = input<ChipStatus>("basic");
  readonly size = input<ChipSize>("md");
  readonly rounded = input<ChipRounded>("full");
  readonly statusClass = computed(() => `chip-ui-${this.status()}`);
  readonly sizeClass = computed(() => `chip-size-${this.size()}`);
  readonly roundedClass = computed(() => `chip-rounded-${this.rounded()}`);
}
